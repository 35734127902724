.works__filter {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.75rem;
  margin-bottom: var(--mb-2);
}

.works__item {
  cursor: pointer;
  color: var(--title-color);
  padding: 0.25rem 0.75rem;
  font-weight: var(--font-medium);
  border-radius: 0.5rem;
  text-transform: capitalize;
}

.works__item:hover {
  background-color: var(--title-color);
  color: var(--container-color);
}

.works__container {
  grid-template-columns: repeat(3, max-content);
  gap: 3rem;
  justify-content: center;
}

.works__card {
  background-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1.25rem;
  border-radius: 1rem;
}

.works__img {
  width: 295px;
  border-radius: 1rem;
  margin-bottom: var(--mb-1);
  height: 200px;
}

.works__title {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-0-5);
}

.works__button {
  color: var(--text-color);
  font-size: var(--small-font-size);
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
}

.works__button-icon {
  font-size: 1rem;
  transition: 0.4s;
}

.works__button:hover .works__button-icon {
  transform: translateX(0.25rem);
}

.active-work {
  background-color: var(--text-color);
  color: var(--container-color);
}

/* =====================Breakpoints===================== */
@media screen and (max-width: 992px) {
  .works__container {
    gap: 1.25rem;
  }

  .works__card {
    padding: 1rem;
  }

  .works__img {
    margin-bottom: 0.75rem;
  }

  .works__title {
    margin-bottom: 0.25rem;
  }
}

@media screen and (max-width: 768px) {
  .works__container {
    grid-template-columns: max-content;
  }
}

@media screen and (max-width: 576px) {
  .works__container {
    grid-template-columns: 1fr;
  }

  .works__img {
    width: 100%;
  }
}

@media screen and (max-width: 350px) {
  .works__item {
    font-size: var(--small-font-size);
  }

  .works__filter {
    column-gap: 0.25rem;
  }
}
