.footer {
  background-color: var(--container-color);
  border-top: 1 px solid rgba(0, 0, 0, 0.1);
}

.footer__container {
  padding: 2rem 0 4rem;
}

.footer__title,
.footer__link {
  color: var(--title-color);
}

.footer__title {
  text-align: center;
  margin-bottom: var(--mb-2);
  font-family: var(--title-firstname);
}

.footer__link:hover {
  color: var(--title-color-dark);
}

.footer__list {
  display: flex;
  justify-content: center;
  column-gap: 1.5rem;
  margin-bottom: var(--mb-2);
}

.footer__copy {
  display: block;
  margin-top: 0.5rem;
  color: var(--title-color);
  text-align: center;
  font-size: var(--smaller-font-size);
}

.footer__message {
  margin-top: 1.5rem;
  text-align: center;
  font-weight: var(--font-normal);
}

.footer__message h3 {
  font-size: var(--h3-font-size);
}
